export default [
  {
    title: 'Risk Assessment',
    icon: 'AlertTriangleIcon',
    route: 'risk-assessment',
    // acl: {
    //   permissions: ['manage-projects'],
    // },
  },
]
